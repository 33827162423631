
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { Actions } from "@/store/enums/StoreEnums";
import { setCookie } from "typescript-cookie";

export default defineComponent({
  name: "dashboard",
  components: {},

  setup() {
    const supplierId = ref();
    const HideGT = ref(false);
    const HideYs = ref(false);
    const store = useStore();
    const router = useRouter();
    const Platform = ref();
    const UserName = ref();
    const Password = ref();
    const Branch = ref();
    const selectedBranch = ref();
    const selectedMenu = ref();
    const Menu = ref();
    const Hide = ref(false);
    const HideMenu = ref(false);
    const okeybutton = async () => {
      if (Platform.value == "Getir") {
        await store
          .dispatch(Actions.GETIR_CHECK, {
            username: UserName.value,
            password: Password.value,
          })
          .then((r) => {
            if (r.status == 201) {
              Branch.value = r.data;
              Hide.value = true;
              HideGT.value = true;
            } else {
              Swal.fire({
                title: "Hata",
                text: "Getir kullanıcı adı ve şifre hatalı",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          });
      }
      if (Platform.value == "Yemeksepeti") {
        store
          .dispatch(Actions.YEMEKSEPETI_CHECK, {
            username: UserName.value,
            password: Password.value,
          })
          .then((r) => {
            if (r.status == 201) {
              Branch.value = r.data;
              Hide.value = true;
              HideYs.value = true;
            } else {
              Swal.fire({
                title: "Hata",
                text: "Yemeksepeti kullanıcı adı ve şifre hatalı",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          });
      }
      if (Platform.value == "Trendyol") {
        await store
          .dispatch(Actions.TRENDYOL_CHECK, {
            supplierId: supplierId.value,
            apiKey: UserName.value,
            apiSecret: Password.value,
          })
          .then((r) => {
            if (r.status == 201) {
              Branch.value = r.data;
              Hide.value = true;
              HideGT.value = true;
              console.log(Branch.value);
            } else {
              Swal.fire({
                title: "Hata",
                text: "Trendyol kullanıcı adı ve şifre hatalı",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          });
      }
    };
    const sendgetir = async (type: string) => {
      const data = {
        updatefor: type,
        Credentials: {
          username: UserName.value,
          password: Password.value,
          selectedBranch: selectedBranch.value,
        },
      };
      await store.dispatch(Actions.ADDNEWCREDENTIAL, data).then((res) => {
        if (res.status == 201) {
          setCookie("access_token", res.data.access_token);
          setCookie("refresh_token", res.data.refresh_token);
          router.push({ name: "dashboard" });
        } else {
          router.push({ name: "sign-in" });
        }
      });
    };
    const sendtrendyol = async (type: string) => {
      const data = {
        updatefor: type,
        Credentials: {
          supplierId: supplierId.value,
          apiKey: UserName.value,
          apiSecret: Password.value,
          selectedBranch: selectedBranch.value,
        },
      };
      await store.dispatch(Actions.ADDNEWCREDENTIAL, data).then((res) => {
        if (res.status) {
          setCookie("access_token", res.data.access_token);
          setCookie("refresh_token", res.data.refresh_token);
          router.push({ name: "dashboard" });
        } else {
          router.push({ name: "sign-in" });
        }
      });
    };
    const sendyemeksepeti = async (type: string) => {
      const data = {
        updatefor: type,
        Credentials: {
          username: UserName.value,
          password: Password.value,
          selectedBranch: selectedBranch.value,
          selectedMenuId: selectedMenu.value,
        },
      };
      await store.dispatch(Actions.ADDNEWCREDENTIAL, data).then((res) => {
        if (res.status == 201) {
          setCookie("access_token", res.data.access_token);
          setCookie("refresh_token", res.data.refresh_token);
          router.push({ name: "dashboard" });
        } else {
          router.push({ name: "sign-in" });
        }
      });
    };
    onMounted(() => {
      setCurrentPageTitle("Yeni Entegrasyon");
    });
    return {
      sendgetir,
      supplierId,
      sendtrendyol,
      sendyemeksepeti,
      Hide,
      selectedMenu,
      HideYs,
      HideMenu,
      HideGT,
      selectedBranch,
      okeybutton,
      Platform,
      UserName,
      Password,
      Branch,
      Menu,
    };
  },
});
